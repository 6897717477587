<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="toggleTable">
			<a-tabs :activeKey="isConverted" @change="changeTab">
				<a-tab-pane key="0" :tab="$t('table.CustomerManagement.Intention.convertedMap.0')"> </a-tab-pane>
				<a-tab-pane key="1" :tab="$t('table.CustomerManagement.Intention.convertedMap.1')" force-render> </a-tab-pane>
			</a-tabs>
		</template>
		<template slot="action" slot-scope="{ columnData }">
			<a v-if="isConverted === '0'" @click="convertItem(columnData.id)">Convert</a>
			<a-divider v-if="isConverted === '0'" type="vertical" />
			<a @click="deleteItem(columnData.id)">Delete</a>
		</template>
	</search-list-table>
</template>
<script>
import moment from 'moment'
import { apiClientLeave, convertLeave, deleteLeave } from '@/api/loan'
export default {
	data() {
		return {
			isConverted: '0'
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.phone_number',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.CustomerManagement.create_time',
					type: 'range-picker',
					validateField: ['submitTime', { initialValue: [moment(new Date().setMonth(new Date().getMonth() - 1)), moment()] }]
				}
			]
		},
		columns() {
			return [
				{
					title: this.$t('table.CustomerManagement.create_time'),
					dataIndex: 'createTime',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.display_name'),
					dataIndex: 'fullName',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.phone_number'),
					dataIndex: 'mobile',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.Permission.User.email'),
					dataIndex: 'email',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: 'keyword',
					dataIndex: 'keyword',
					align: 'center',
					customRender: (v, o) => {
						return v ? decodeURIComponent(v) : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.vehicleLabel.vehicle'),
					dataIndex: 'make',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.vehicleLabel.model'),
					dataIndex: 'model',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.vehicleLabel.year'),
					dataIndex: 'yom',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.financialLabel.loanType'),
					dataIndex: 'loanType',
					align: 'center'
				},
				{
					title: this.$t('table.CustomerManagement.IntentionDetail.expectLoanAmount'),
					dataIndex: 'expectLoanAmount',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 150,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		moment,
		initDataSource(initParams) {
			return apiClientLeave({ ...initParams, isConverted: Number(this.isConverted) })
		},
		changeTab(key) {
			this.isConverted = key
			this.$refs.searchListTable.initTable(1)
		},
		convertItem(val) {
			this.$confirm({
				content: this.$t('page.confirm'),
				onOk: async () => {
					try {
						await convertLeave({ id: val })
						this.$refs.searchListTable.initTable(1)
					} catch (e) {
						this.$error({
							content: e.message
						})
					}
				}
			})
		},
		deleteItem(val) {
			this.$confirm({
				content: this.$t('page.confirm'),
				onOk: async () => {
					try {
						await deleteLeave({ id: val })
						this.$refs.searchListTable.initTable(1)
					} catch (e) {
						this.$error({
							content: e.message
						})
					}
				}
			})
		}
	}
}
</script>
<style scoped lang="less"></style>
